@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
@import url('https://rsms.me/inter/inter.css');

body {
  margin: 0;
  text-align: center;
  font-family: 'Press Start 2P', cursive;
  user-select: none;
}
header {
  margin: 0 auto;
  width: 431px;
}
h1 {
  background: url("https://i.ibb.co/Q9yv5Jk/flappy-bird-set.png") 0% 340px;
  padding: 1.2rem 0;
  margin: 0;
}
.score-container {
  display: flex;
  justify-content: space-between;
  padding: 8px 6px;
  background: #5EE270;
}

#main {
  position: relative;
  margin: 0;
}
#canvas-wrapper {
  position: relative;
}

.game-wrapper {
  display: flex;
  justify-content: center;
}


:root {
  --color-light: white;
  --color-dark: #212121;
  --color-signal: #5ee270;

  --color-background: var(--color-light);
  --color-text: var(--color-dark);
  --color-accent: var(--color-signal);

  --size-bezel: .5rem;
  --size-radius: 4px;

  line-height: 1.4;

  font-family: 'Inter', sans-serif;
  font-size: calc(.6rem + .4vw);
  color: var(--color-text);
  background: var(--color-background);
  font-weight: 300;
  padding: 0 calc(var(--size-bezel) * 3);
}

h1, h2, h3 {
  font-weight: 900;
}

mark {
  background: var(--color-accent);
  color: var(--color-text);
  font-weight: bold;
  padding: 0 0.2em;
}

.card {
  background: var(--color-background);
  padding: calc(4 * var(--size-bezel));
  margin-top: calc(4 * var(--size-bezel));
  border-radius: var(--size-radius);
  border: 3px solid var(--color-shadow, currentColor);
  box-shadow: .5rem .5rem 0 var(--color-shadow, currentColor);

  *:first-child {
    margin-top: 0;
  }
}


.l-design-widht {
  max-width: 40rem;
  padding: 1rem;
}

.input {
  position: relative;
}

.input__label {
  position: absolute;
  display: flex;
  top: 0;
  padding: calc(var(--size-bezel) * 0.75) calc(var(--size-bezel) * .5);
  margin: calc(var(--size-bezel) * 0.75 + 3px) calc(var(--size-bezel) * .5);
  background: pink;
  white-space: nowrap;
  transform: translate(0, 0);
  transform-origin: 0 0;
  background: var(--color-background);
  transition: transform 120ms ease-in;
  font-weight: bold;
  line-height: 1.2;
}

.input__field {
  box-sizing: border-box;
  display: block;
  width: 250px;
  border: 3px solid currentColor;
  padding: calc(var(--size-bezel) * 1.5) var(--size-bezel);
  color: currentColor;
  background: transparent;
  border-radius: var(--size-radius);
}

.input__field:focus + .input__label, .input__field:not(:placeholder-shown) + .input__label {
  transform: translate(.25rem, -65%) scale(.8);
  color: var(--color-accent);
}


.button-group {
  margin-top: calc(var(--size-bezel) * 2.5);
}

.hidden {
  display: none;
}

